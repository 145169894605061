<template>
  <div class="tresc">
    <p>
      <strong>Lekarski Gabinet Zabiegowy "URO"</strong> jest miejscem, gdzie mogą skorzystać Państwo z usług
      doświadczonych lekarzy specjalności takich jak:
    <ul>
      <li v-for="branch in branches" :key="branch">{{ branch.name }}</li>
    </ul>
    </p>
    <p>
      Wszyscy przyjmujący u nas lekarze są zarejestrowani w
      <a href="https://oilgdansk.pl/">Okręgowej Izbie Lekarskiej
      </a>oraz posiadają bogate doświadczenie zawodowe.
    </p>
    <p>
      Lekarski Gabinet Zabiegowy URO prowadzi działalność od 1994 roku.
    </p>
    <p>
      Zapraszamy Państwa do korzystania z naszych usług.
    </p>
  </div>
</template>
<script>
export default {
  name: 'AboutView',
  props: ['branches'],
}
</script>

<style scoped>

</style>
