<template>
  <div class="tresc">
    <p>
      Gabinet zabiegowy "URO" mieści się w Gdańsku Wrzeszczu przy ulicy Partyzantów, pod numerem 49 B, vis-a-vis
      ulicy Topolowej.
    </p>
    <iframe width="80%" height="350"
            src="https://www.openstreetmap.org/export/embed.html?bbox=18.595052361488346%2C54.37885931401372%2C18.598077893257145%2C54.3801590272575&amp;layer=mapnik"
            style="border: 1px solid black"></iframe>
    <br/><small><a href="https://www.openstreetmap.org/#map=19/54.37951/18.59657">Powieksz</a></small>
    <p>Aby dojechać samochodem od ulicy Grunwaldzkiej należy:
    <ul>
      <li>skręcić w prawo w ulicę Jesionową</li>
      <li>po dotarciu do ulicy Partyzantów skręcić w prawo</li>
      <li> po przejechaniu okolo 100 metrów ponownie skręcić w prawo.</li>
    </ul>
    Możliwy jest również dojazd ulicą Partyzantów, dokładne położenie gabinetu przedstawia załączona mapka</p>
  </div>
</template>

<script>
export default {
  name: "LocationView",
}
</script>

<style scoped>
iframe {
  border: 0;
}
</style>



