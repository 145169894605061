<template>
  <div class="tresc">
    <p><strong> Lekarski Gabinet Zabiegowy "Uro"</strong> <br>
      lek. med. Stanisław Dobrzański <br>
      Ulica Partyzantów 49 B, vis-a-vis ul. Topolowej <br>
      80-254 Gdańsk Wrzeszcz <br>
    </p>
    <p>
      Kontakt telefoniczny: <strong> 58 341 97 83</strong> <br>
      Pod powyższym numerem dostępna również całodobowa informacja telefoniczna (automat). <br><br>
      Zapraszamy
    </p>
  </div>
</template>

<script>
export default {
  name: "ContactPage"
}
</script>

<style scoped>

</style>