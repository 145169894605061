<template>
  <div class="tresc">
    <div v-for="(branch,index) in branches" :key="branch" :class="{'even': index % 2 === 0, 'odd': index % 2 !== 0 }">
      <b>{{ branch.name }} </b>
      <div v-for="doctor in branch.doctors" :key="doctor">
        {{ doctor.name }}<br>
        <span v-for="workingHour in doctor.workingHours" :key="workingHour"> <i>{{ workingHour }} </i></span>
        <span v-if="doctor.notes"><br><b>Uwaga</b>: {{ doctor.notes }} </span>

      </div>
    </div>
  </div>
</template>

<script>
// import doctorsData from '../data/doctors.json'
export default {
  name: "DoctorsView",
  props: ['branches'],
}

</script>

<style scoped>
div {
  margin: 10px;
}

div.odd {
  background-color: #e5e8cd;
}

div.even {
  background-color: #e8d695;
}
</style>