import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '@/views/AboutView'
import LocationView from '@/views/LocationView';
import DoctorsView  from "@/views/DoctorsView";
import ContactPage from "@/views/ContactPage";

import doctorsData from '../data/doctors.json'
const branches = doctorsData.branches

const routes = [
  {
    path: '/',
    alias: '/onas',
    name: 'home',
    component: AboutView,
    props: { branches: branches }

  },
  {
    path: '/dojazd',
    name: 'location',
    component: LocationView
  },
  {
    path: '/lekarze',
    name: 'lekarze',
    component: DoctorsView,
    props: { branches: branches }
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: ContactPage
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
