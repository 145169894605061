<template>
  <div class="menu">
    <nav>
      <router-link to="/onas">O nas</router-link>
      <router-link to="/dojazd">Dojazd</router-link>
      <router-link to="/lekarze">Lekarze</router-link>
      <router-link to="/kontakt">Kontakt</router-link>

    </nav>
  </div>
  <router-view/>
  <!--    <footer>Ostatnia modyfikacja: none</footer>-->
</template>
<script>

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*font-size: larger;*/
  padding-top: 5%;
}

div.menu {
  /*padding: 5px;*/
  border-color: #a8a8a8;
  background-color: #d9e6f6;
  margin-bottom: 30px;
  border-width: 1px;
  border-style: solid;
}

nav {
  /*padding: 50px;*/
}

nav a {
  font-size: large;
  font-weight: bold;
  color: blue;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;

}

nav a.router-link-exact-active {
  /*color: #42b983;*/
  background-color: #dbdde0;
  font-size: x-large;
}

nav a:hover {
  background-color: yellow;
}

body {
  background-image: url(assets/tlo.png);
  background-repeat: repeat;
}

div.tresc {
  border-color: #a8a8a8;
  background-color: #d9e6f6;
  /*width: 80%;*/
  position: center;
  border-width: 1px;
  border-style: solid;
}

ul {
  list-style-type: none;
  margin-top: 0;

}
</style>
